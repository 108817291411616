//  General styles and overrides that are applicable globally.
@import './normalize';
@import './mixins';

$main-color1: #ffffff;
$main-color2: #dcdcdc;
// $main-color3: #ff9500;
$main-color3: #000000;
$main-color4: #ff7900;
$main-color5: #f44336;
$main-color6: #000000;
$main-color7: #ffc533;
$main-color8: #49d724;

$main-color9: #f4f2ea;
$main-color10: #efb354;
$main-color11: #d9d9d9;
$main-color12: #ff7900;
$main-color13: #673ab7;
$main-color14: #ee7d56;
$main-color15: #ea3352;

$border-color1: #cccccc;
$border-color2: #ebebeb;
$border-color3: #d5d5d5;

*:focus {
  outline-color: $main-color3;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0 10px;
}

.main-layout {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .main-layout {
    max-width: 1140px;
  }
}

body {
  font-family: 'Manrope', sans-serif;
  font-size: 0.875rem;
}

button:focus {
  outline: none;
}

//Define global colors
:root {
  --main-color1: #{$main-color1};
  --main-color2: #{$main-color2};
  --main-color3: #{$main-color3};
  --main-color4: #{$main-color4};
  --main-color5: #{$main-color5};
  --main-color6: #{$main-color6};
  --main-color7: #{$main-color7};
  --main-color8: #{$main-color8};

  --main-color9: #{$main-color9};
  --main-color10: #{$main-color10};
  --main-color11: #{$main-color11};
  --main-color12: #{$main-color12};
  --main-color13: #{$main-color13};
  --main-color14: #{$main-color14};
  --main-color15: #{$main-color15};
  --border-color1: #{$border-color1};
  --border-color2: #{$border-color2};
  --border-color3: #{$border-color3};
}

////Define new global colors
//:root {
//  --main-color1: #FFFFFF;
//  --main-color2: #F4F2EB;
//  --main-color3: #6127DF;
//  --main-color4: #6127DF;
//  --main-color5: #FF004D;
//  --main-color6: #000000;
//  --main-color7: #FAAF3B;
//  --main-color8: #49d724;
//  --main-color9: #FF764B;
//  --main-color10:#DEDCD6;
//}

.avatar {
  border-radius: 50%;
}

.editor .tox-tinymce {
  border: 1.5px solid var(--main-color3);
  border-radius: 5px;
}

.mat-mdc-snack-bar-container {
  &.success-snackbar {
    --mdc-snackbar-container-color: #d4edda;
    --mat-mdc-snack-bar-button-color: green;
    --mdc-snackbar-supporting-text-color: #155724;
  }
}

.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: #f8d7da;
    --mat-mdc-snack-bar-button-color: red;
    --mdc-snackbar-supporting-text-color: #721c24;
  }
}

.pull-right {
  float: right;
}

.align-center {
  display: flex;
  align-items: center;
}

.flex-columns {
  display: flex;
  flex-direction: column;
}

.section-title {
  font-size: 28px;
  font-weight: 500;
  color: #000000;
  border-bottom: 1px solid rgb(222, 226, 230);
  margin: 24px 0 18px 0;
  text-align: left;
}

.icon {
  font-size: 1.5rem;
  margin: 0 0.25rem;
  cursor: pointer;
  color: var(--main-color3);
}

.icon:hover {
  color: var(--main-color6);
}

.icon.delete {
  color: var(--main-color5);
}

.buttons-wrapper-right {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.margin-24-top {
  margin-top: 24px;
}

.inputs-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}

.form-container-padding {
  padding: 10px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.flex {
  display: flex;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.excel-button {
  min-height: 56px;
  margin-bottom: 20px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-button {
  min-height: 56px;
}

.cancel-button {
  border-radius: 0 !important;
  border: 1px solid var(--main-color3) !important;
}

.calendar-search-field {
  .mdc-text-field:not(.mdc-text-field--disabled) {
    height: 40px;
    align-items: center;
    border-radius: 0 !important;
    border: 1px solid var(--border-color2);

    mat-icon {
      font-size: 20px;
      padding: 10px 0 10px 10px;
    }

    .close-icon {
      cursor: pointer;
    }
  }
}

.new-menu-holder {
  width: 100%;
  height: 40px;
  justify-content: flex-start;
  border-radius: 0;
  padding: 10px;
  border: 1px solid var(--border-color2);
}

.custom-calendar-menu {
  min-width: 370px !important;
}

/* Avatar */
.avatar {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 15px;
}

.p-tree-toggler {
  display: none;
}

.p-treenode {
  overflow: hidden;
}

//min-height/width added to avoid dissapearing on zoom out
.p-tree.p-tree-horizontal .p-treenode {
  min-height: 2px;
  background: url('../../../assets/img/dot-x.png') repeat-x scroll center center transparent !important;
}

.p-tree.p-tree-horizontal .p-treenode-connector-line {
  min-width: 2px;
  background: url(../../../assets/img/dot-y.png) repeat-y scroll 0 0 transparent;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.clickable {
  cursor: pointer !important;
  * {
    cursor: pointer !important;
  }
}

.relative {
  position: relative;
}

.overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.cell-link {
  display: block;
  width: 100%;
  &:hover {
    text-decoration: underline;
  }
}

.icon-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  display: block;
}

.uppercase {
  text-transform: uppercase;
}

.tooltip-multiline {
  white-space: pre-line;
}

// new darker bg design related
body.darker-bg {
  background-color: $main-color9;

  #main {
    margin-bottom: 0;
  }

  .mat-mdc-raised-button,
  .mat-mdc-unelevated-button {
    &.mat-primary:before {
      border-top-color: $main-color9;
    }
  }
}

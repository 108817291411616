@use 'sass:math';

@mixin outline-base {
  transition: outline-width 0.1s;
  outline-style: solid;
  outline-width: 0px;
  outline-color: rgba(#ff9500, 0.4);
  outline-offset: 0px;
  &:focus {
    outline-width: 3.5px;
  }
}

@mixin withTriangleCorner($height) {
  position: relative;
  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    $triangle-width: calc($height/100 * 41.66);
    width: $triangle-width;
    height: $triangle-width;
    border-width: $triangle-width;
    transform: rotate(-45deg) translate(math.div($height, 100) * 61.11, 0px);
    border-style: solid;
    border-color: white;
    left: initial;
    border-color: transparent transparent transparent white;
  }
}

@mixin common-scroll {
  overflow-y: auto;
  scrollbar-color: var(--main-color6) var(--main-color11);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color6); /* Set thumb color for Chrome and Safari */
  }

  &::-webkit-scrollbar-track {
    background-color: var(--main-color11); /* Set track color for Chrome and Safari */
  }
}
